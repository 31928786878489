// src/components/Duas.js
import React from 'react';
import '../assests/styles/Duas.scss';
import HomeIcon from './HomeIcon';
import DuaForCourageAndClarity from '../assests/images/DuaForCourageAndClarity.jpg';
import DuaForStrengthAndDivineHelp from '../assests/images/DuaForStrengthAndDivineHelp.jpg';
import DuaForInnerPeace from '../assests/images/DuaForInnerPeace.jpg';
import DuaForRepentanceAndComfortOfAllah from '../assests/images/DuaForRepentanceAndComfortOfAllah.jpg';
import DuaforTotalTrustinAllahinAnySituation from '../assests/images/DuaforTotalTrustinAllahinAnySituation.jpg';
import DuaforForgivenessandMercyofAllah from '../assests/images/DuaforForgivenessandMercyofAllah.jpg';
import DuaforGoodnessfromAllahinAnySituation from '../assests/images/DuaforGoodnessfromAllahinAnySituation.jpg'
import DuaforProtectionfromJealousyandEnvy from '../assests/images/DuaforProtectionfromJealousyandEnvy.jpg';
import DuaforAcceptanceofGoodDeeds from '../assests/images/DuaforAcceptanceofGoodDeeds.jpg';
import DuaforGoodandOnFaithDeath from '../assests/images/DuaforGoodandOnFaithDeath.jpg';
import DuaforRighteousandLovingSpouse from '../assests/images/DuaforRighteousandLovingSpouse.jpg';
import DuaforRighteousandVirtuousOffspring from '../assests/images/DuaforRighteousandVirtuousOffspring.jpg';

// Sample Duas data (list of YouTube short video IDs)
const duas = [
    {
        name: "Dua For Courage And Clarity",
        image: DuaForCourageAndClarity,
        link: "https://www.youtube.com/shorts/BXW5DnI-VM4",
    },
    {
        name: "Dua For Strength And Divine Help",
        image: DuaForStrengthAndDivineHelp,
        link: "https://www.instagram.com/p/DB6JXE3tcWU/?img_index=1",
    },
    {
        name: "Dua For Inner Peace And Reliance on Allah",
        image: DuaForInnerPeace,
        link: "https://www.instagram.com/p/DCExuz1Nl2z/?img_index=1",
    },
    {
        name: "Dua For Repentance And Comfort of Allah",
        image: DuaForRepentanceAndComfortOfAllah,
        link: "https://www.youtube.com/shorts/Z6dApMZxgq8",
    },
    {
        name: "Dua For Total Trust In Allah In Any Situation",
        image: DuaforTotalTrustinAllahinAnySituation,
        link: "https://www.youtube.com/shorts/uNV9XzH6OYU",
    },
    {
        name: "Dua For Forgiveness and Mercy of Allah",
        image: DuaforForgivenessandMercyofAllah,
        link: "https://www.youtube.com/shorts/mAMGa65v3cw",
    },
    {
        name: "Dua For Goodness from Allah in Any Situation",
        image: DuaforGoodnessfromAllahinAnySituation,
        link: "https://www.youtube.com/shorts/zTsrZoOUkaM",
    },
    {
        name: "Dua for Protection from Jealousy and Envy",
        image: DuaforProtectionfromJealousyandEnvy,
        link: "https://www.youtube.com/shorts/QezM04XENq8",
    },
    {
        name: "Dua for Acceptance of Good Deeds",
        image: DuaforAcceptanceofGoodDeeds,
        link: "https://www.youtube.com/shorts/uGqxnz4PMWo",
    },
    {
        name: "Dua for Good and On-Faith Death",
        image: DuaforGoodandOnFaithDeath,
        link: "https://www.youtube.com/shorts/fwREFgL1zwI",
    },
    {
        name: "Dua for Righteous and Loving Spouse",
        image: DuaforRighteousandLovingSpouse,
        link: "https://www.youtube.com/shorts/mm4k9RqN730",
    },
    {
        name: "Dua for Righteous and Virtuous Offspring",
        image: DuaforRighteousandVirtuousOffspring,
        link: "https://www.youtube.com/shorts/Qor9qORhd_Y",
    },
];

const Duas = () => {
    return (
        <div>
            <HomeIcon />
            <div className="Duas-Page">
                <div className="Duas-Title">
                    <h1 className="Duas-Title0">AmongIslam</h1>
                    <h1 className="Duas-Title1">Duas</h1>
                </div>
                <div className="Duas-Container">
                    {duas.map((dua, index) => (
                        <div className="Duas-Card" key={index}>
                            <a href={dua.link} target="_blank" rel="noopener noreferrer">
                                <img src={dua.image} alt={`${dua.name}'s profile`} className="Duas-Image" />
                                {/* <div className='Duas-Info'>
                                    <h2>{dua.name}</h2>
                                </div> */}
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Duas;
